<template>
  <member-setting-container>
    <b-card class="p-1">
      <div class="d-flex flex-wrap justify-content-between ml-50 mb-2">
        <h4>{{ $t('tickets.title') }}</h4>
      </div>
      <b-list-group v-if="items && items.length > 0">
        <b-list-group-item
          v-for="item in items"
          :key="item[0].key"
          style="border-radius:0"
          class="accordion"
          role="tablist"
          @click="openTicket(item)"
        >
          <b-row
            v-b-toggle="`collapse-${item[0].key}`"
            class="d-flex"
            data-toggle="collapse"
          >
            <b-col cols="2">
              <b-img
                fluid
                :src="item[0].event.logoURL"
                :alt=" translate(item[0].event.name)"
              />
            </b-col>
            <b-col cols="10">
              <h4> {{ translate(item[0].event.name) }}</h4>
              <p v-if="translate(item[0].event.headline)">
                {{ translate(item[0].event.headline) }}
              </p>
              <b-row v-if="item[0].event.startDate ||item[0].event.endDate||item[0].event.mainType==='Event'" class="mb-50">
                <b-col v-if="item[0].event.startDate ||(!item[0].event.startDate && !item[0].event.endDate)" :cols="(!item[0].event.startDate && !item[0].event.endDate) || item[0].event.endDate?6:9" :sm="(!item[0].event.startDate && !item[0].event.endDate)||item[0].event.endDate?6:12">
                  <p class="mb-0" style="font-size: 0.8rem; font-weight: 600">
                    {{ $t("community-others.start") }}
                  </p>
                  <p v-if="item[0].event.startDate" class="mb-0">
                    {{ timestampToTime(item[0].event.startDate) }} - {{ timestampToHour(item[0].event.startDate) }} h
                  </p>

                  <p v-if="!item[0].event.startDate && !item[0].event.endDate" class="mb-0">
                    {{ $t("community-others.coming-soon") }}
                  </p>
                </b-col>
                <b-col v-if="item[0].event.endDate ||(!item[0].event.startDate && !item[0].event.endDate)" :cols="(!item[0].event.startDate && !item[0].event.endDate)||item[0].event.startDate?6:9" :sm="(!item[0].event.startDate && !item[0].event.endDate)||item[0].event.startDate?6:12">
                  <p class="mb-0" style="font-size: 0.8rem; font-weight: 600">
                    {{ $t("community-others.end") }}
                  </p>
                  <p v-if="item[0].event.endDate" class="mb-0">
                    {{ timestampToTime(item[0].event.endDate) }} - {{ timestampToHour(item[0].event.endDate) }} h
                  </p>

                  <p v-if="!item[0].event.startDate && !item[0].event.endDate" class="mb-0">
                    {{ $t("community-others.coming-soon") }}
                  </p>
                </b-col>
              </b-row>
              <b-link
                :to="{ name: 'event', params: { slug: item[0].event.slug, communityId: $route.params.communityId } }"
                class="small mt-2"
              >
                {{ `Ir al ${$t(`collectives.items.${[item[0].event.mainType]}`)}` }}
              </b-link>
            </b-col>
          </b-row>

          <b-collapse
            :id="`collapse-${item[0].key}`"
            accordion="my-faqs"
            class="mx-1 mt-2 answer-collapse"
          >
            <b-row class="border-top">
              <b-col
                cols="12"
                xl="6"
                :class="{ 'd-flex justify-content-center': isMedium }"
              >
                <div v-if="ticketPdf" class="d-flex flex-column align-items-center justify-content-center mt-5">
                  <pdf
                    :src="`data:application/pdf;base64,${ticketPdf}`"
                  />
                </div>
                <div v-else class="d-flex flex-column align-items-center justify-content-center mt-5">
                  <b-spinner variant="primary" class="mb-2" />
                  <h5 class="text-primary">
                    {{ $t('loading') }}
                  </h5>
                </div>
              </b-col>
              <b-col>
                <!--                 <b-alert
                  :show="copied"
                  variant="success"
                  class="text-center my-2 w-50"
                  :class="{ 'd-block mx-auto': !isMedium }"
                >
                  {{ $t('share.copied-message') }}
                </b-alert> -->
                <div v-if="selectedTicket" class="mt-2 mb-2 pt-2 ">
                  <div class="d-flex flex-column align-items-center">
                    <feather-icon icon="CheckCircleIcon" size="38" class="text-success mb-1" />
                    <h4 class="mb-3 text-center">
                      Hola {{ member.name }}, aquí tienes tus entradas para {{ translate(selectedTicket[0].event.name) }}. Elige una opción para llevarlas contigo:
                    </h4>
                  </div>

                  <div class="mb-3">
                    <h6>
                      Guárdalas en la cartera virtual de tu móvil:
                    </h6>
                    <div class="d-flex">
                      <b-button
                        class="font-weight-bold d-flex mt-1 align-items-center"
                        :class="{ 'mr-1': !isSmall }"
                        :variant="'primary'"
                      >
                        <b-icon
                          icon="wallet-fill"
                          scale="1.6"
                          variant="white"
                          class="mr-1"
                        />
                        <span class="d-flex flex-column align-items-start">
                          <span class="small">Add to</span> <span>Apple Wallet</span>
                        </span>
                      </b-button>
                      <b-button
                        class="font-weight-bold d-flex mt-1 align-items-center"
                        :class="{ 'mr-1': !isSmall }"
                        :variant="'primary'"
                      >
                        <b-icon
                          icon="journal-bookmark-fill"
                          scale="1.6"
                          variant="white"
                          class="mr-1"
                        />
                        <span class="d-flex flex-column align-items-start">
                          <span class="small">Add to</span> <span>Pass Wallet</span>
                        </span>
                      </b-button>
                    </div>
                  </div>
                  <div class="mt-2">
                    <h6 class="mb-50 mt-2">
                      <b-icon
                        icon="printer"
                        scale="1.2"
                        variant="success"
                        class="mr-50"
                      />
                      Imprímelas descargandote el documento adjunto:
                    </h6>
                    <b-button
                      class="font-weight-bold d-flex mt-1 align-items-center"
                      :class="{ 'mr-1': !isSmall }"
                      :variant="'primary'"
                      @click="openPdf"
                    >
                      <b-icon
                        icon="printer"
                        scale="1.6"
                        variant="white"
                        class="mr-1"
                      />
                      Descargar
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-collapse>
        </b-list-group-item>
      </b-list-group>
      <div v-else class="d-flex placeholder align-item-center justify-content-center flex-column my-2" style="min-height: 45vh">
        <b-img :src="imgUrl" class="placeholder-img" center />
        <div class="mt-2 text-center">
          <p class="text-primary font-weight-500">
            Aún no tienes ninguna entrada
          </p>
        </div>
      </div>
    </b-card>
  </member-setting-container>
</template>

<script>
import MemberSettingContainer from '@/views/myself/components/MemberSettingContainer.vue';
import Service from '@/config/service-identifiers';
import PassportCard from '@core/components/members/Passport.vue';
import { setTimeout } from 'timers';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import EventsPlaceholder from '@/assets/images/placeholders/light/events.svg';

import moment from 'moment';
import pdf from 'vue-pdf';

export default {
  name: 'Passport',
  components: {
    MemberSettingContainer,
    PassportCard,
    pdf,
  },
  data() {
    return {
      isStaff: false,
      isModalOpened: false,
      // isCopied: false,
      isModalAttendanceOpened: false,
      isSmall: false,
      isMedium: false,
      loggedMember: null,
      printing: false,
      //  copied: false,
      openPDFVar: false,
      lastLoadedPage: 1,
      selectedTicket: '',
      ticketPdf: null,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    itemsData() {
      return this.$store.getters.tickets;
    },
    items() {
      return this.itemsData?.unpaginated;
    },
    member() {
      return this.$store.getters.loggedMember;
    },
    mainCommunity() {
      return this.$store.getters.mainCollective;
    },
    imgUrl() {
      return EventsPlaceholder;
    },
    qrUrl() {
      const url = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        '{subdomain}',
        this.currentCollective.subdomain || 'app',
      ).replace('{slug}', this.currentCollective.slug);
      return `${url}/backoffice/members/${this.loggedMember.username}`;
    },
    link() {
      return `https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${encodeURIComponent(
        this.qrUrl,
      )}&choe=UTF-8`;
    },
  },
  watch: {
    ticketPdf(newValue) {
      if (this.openPDFVar) {
        const pdfWindow = window.open('');
        pdfWindow.document.write(
          `<iframe width='100%' height='100%' src='data:application/pdf;base64,${this.ticketPdf}'></iframe>`,
        );
        this.openPDFVar = false;
      }
    },
  },
  async created() {
    // this.isStaff = this.currentCollective.isStaff;
    await this.fetchData();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'tickets',
        page: this.lastLoadedPage,
        forceAPICall: true,
        perPage: 30,
      });
      this.loggedMember = this.member;
    },
    async openTicket(item) {
      this.selectedTicket = item;
      await this.fetchTicket();
    },
    async fetchTicket() {
      const response = await this.$store.$service[Service.BackendClient].get('/ticketPDF', {
        params: {
          communitySlug: this.selectedTicket[0].event.slug,
          groupUUID: this.selectedTicket[0].groupUUID,
        },
      });
      this.ticketPdf = response.data;
    },
    /*     async fetchUser() {
      const response = await this.$store.$service[Service.BackendClient].get(
        '/communityMembers',
        {
          params: {
            communitySlug: this.$store.getters.currentCollective.slug,
            username: this.member.username,
            isForProfile: true,
          },
        },
      );

      const member = response.data.data[0];

      this.loggedMember = member;
    }, */
    handlePrintCode() {
      this.printing = true;
    },
    /*
    () {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 3500);
    }, */
    handleResize() {
      this.isSmall = window.innerWidth < 400;
      this.isMedium = window.innerWidth > 1100;
    },
    timestampToTime(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).format('LL');
    },
    timestampToHour(timestamp) {
      return moment(timestamp).format('HH:mm');
    },
    openPdf() {
      if (!this.ticketPdf) {
        this.openPDFVar = true;
        return;
      }
      const pdfWindow = window.open('');
      pdfWindow.document.write(
        `<iframe width='100%' height='100%' src='data:application/pdf;base64,${this.ticketPdf}'></iframe>`,
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.placeholder-img {
  width: 180px;
  display: block;
}
</style>
